<template>
	<form @submit.prevent="handleSubmit(!v$.$invalid)" class="p-grid">
		<Toast />
		<ConfirmDialog></ConfirmDialog>
		
		<div class="grid p-fluid stickBar">
			<div class="col-12">
				<div class="card card-w-title" id="stickBar">
					<Menubar :model="nestedMenuitems">
						<template #end>
							<div class="flex justify-content-end flex-wrap card-container green-container">
								<div
									class="flex align-items-center justify-content-center font-bold text-gray-500 text-xl m-2">
									{{ mainData.baslik }}
								</div>
								<div class="flex align-items-center justify-content-center m-2">
									<Button class="p-button-rounded p-button-danger mr-0 mb-0"
										v-if="$router.options.history.state.back != null" icon="pi pi-times"
										@click="$router.go(-1)" />
								</div>
							</div>
						</template>
					</Menubar>
				</div>
			</div>
		</div>

		<div class="grid">
			<div class="col-12">
				<div class="card">
					<div class="grid dashboard">
						<div class="col">
							<div class="card overview-box flex flex-column pt-2 cyan-bgcolor solid-surface-text-color">
								<div class="flex align-items-center">
									<i class="pi pi-shopping-cart"></i>
									<h6 class="m-2" :class="{ 'pl-2': !isRTL, 'pr-2': isRTL }">Siparis Kimliği</h6>
								</div>
								<div class="flex justify-content-between mt-1 flex-wrap" style="height:40px">
									<div class="flex flex-column">
										<span class="overview-status p-1 fs-normal">{{ mainData.siparisKimligi }}</span>
									</div>
								</div>
							</div>
						</div>

						<div class="col">
							<div
								class="card overview-box flex flex-column pt-2 orange-bgcolor solid-surface-text-color">
								<div class="flex align-items-center">
									<i class="pi pi-dollar"></i>
									<h6 class="m-2" :class="{ 'pl-2': !isRTL, 'pr-2': isRTL }">Satış Projesi</h6>
								</div>
								<div class="flex justify-content-between mt-1 flex-wrap" style="height:40px">
									<div class="flex flex-column">
										<span class="overview-status p-1 fs-normal">{{ mainData.satisProjesiName
											}}</span>
									</div>
								</div>
							</div>
						</div>

						<div class="col">
							<div class="card overview-box flex flex-column pt-2 green-bgcolor solid-surface-text-color">
								<div class="flex align-items-center">
									<i class="pi pi-dollar"></i>
									<h6 class="m-2" :class="{ 'pl-2': !isRTL, 'pr-2': isRTL }">Durum</h6>
								</div>
								<div class="flex justify-content-between mt-1 flex-wrap" style="height:40px">
									<div class="flex flex-column">
										<span class="overview-status p-1 fs-normal">{{ mainData.durumAciklamasi
											}}</span>
									</div>
								</div>
							</div>
						</div>

						<div class="col">
							<div class="card overview-box flex flex-column pt-2 pink-bgcolor solid-surface-text-color">
								<div class="flex align-items-center">
									<i class="pi pi-users"></i>
									<h6 class="m-2" :class="{ 'pl-2': !isRTL, 'pr-2': isRTL }">Genel Toplam</h6>
								</div>
								<div class="flex justify-content-between mt-1 flex-wrap" style="height:40px">
									<div class="flex flex-column">
										<span class="overview-status p-1 fs-large">{{ formatNumber(mainData.genelToplam)
											}}</span>
										<span class="overview-status p-1 fs-small">İndirim:
											{{ formatNumber(mainData.indirimToplami) }}</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="grid">
			<div class="col-12">
				<div class="card">
					<EntityHeader label="SİPARİŞ" entityName="salesorder"></EntityHeader>
					<div class="grid">
						<div class="col-9">
							<div class="field p-fluid">
								<EntityLookup id="firma" v-model="mainData.firmaName" ref="entity_firma" label="Firma"
									entityName="account" nameField="name" :state="true"
									@itemSelected="firmaSelected = $event" @itemCleared="firmaSelected = null" disabled>
								</EntityLookup>
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<EntityLookup id="ilgilikisi" v-model="mainData.ilgiliKisiName" ref="entity_ilgilikisi"
									label="İlgili Kişi" entityName="contact" nameField="fullname" :state="true" :disabled="isDisabled"
									parentFieldName="parentcustomerid" :parentId="firmaSelected" required
									@itemSelected="ilgiliKisiSelected = $event"
									@itemCleared="ilgiliKisiSelected = null">
								</EntityLookup>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="col-12">
				<div class="card">
					<h5>SİPARİŞ DETAYLARI</h5>
					<div class="grid">
						<div class="col-3">
							<div class="field p-fluid">
								<label for="siparisTarihi">Sipariş Tarihi <span style="color:red">*</span></label>
								<Calendar showButtonBar id="siparisTarihi" v-model="mainData.siparisTarihi" autocomplete="off" :showIcon="true" :disabled="isDisabled" />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<EntityLookup id="teklifVerenKisi" v-model="mainData.siparisVerenKisiName"
									ref="entity_teklifVerenKisi" label="Sipariş Veren Kişi" required
									entityName="bm_teknikkullanicilar" nameField="bm_name" :state="true" :disabled="isDisabled"
									@itemSelected="teklifVerenKisiSelected = $event"
									@itemCleared="teklifVerenKisiSelected = null"></EntityLookup>
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="satisYontemi">Satış Yöntemi</label>
								<Dropdown id="satisYontemi" v-model="mainData.satisYontemi" :options="SM_satisYontemi"
									optionLabel="Value" optionValue="AttributeValue" placeholder="Satış Yönetimi"
									:showClear="true" :disabled="isDisabled" />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<EntityLookup id="tezgah" v-model="mainData.tezgahName" ref="entity_tezgah"
									label="Tezgah" entityName="bm_unite" nameField="bm_serino" :state="true" disabled
									secondNameField="bm_urunid" secondNameFieldType="lookup" @itemSelected="tezgahSelected = $event"
									@itemCleared="tezgahSelected = null" parentFieldName="bm_siparisnoid"
									:parentId="{ 'Value': entityId }"></EntityLookup>
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="urunAilesi">Ürün Ailesi</label>
								<TreeSelect id="urunAilesi" v-model="selectedUrunAilesi" disabled
									:options="urunAilesiNodes.jsonData" placeholder="Seçiniz"
									@node-select="urunAilesiSelect($event)"></TreeSelect>
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<EntityLookup id="urun" v-model="mainData.urunName" ref="entity_urun" label="Ürün"
									entityName="product" nameField="name" :state="true" disabled
									@itemSelected="urunSelected = $event" @itemCleared="urunSelected = null"
									secondNameField="productnumber">
								</EntityLookup>
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="adet">Adet</label>
								<InputNumber id="adet" v-model="mainData.adet" mode="decimal" locale="tr-TR" disabled
									:minFractionDigits="0" :min="0" :showButtons="true" />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<EntityLookup id="satisprojesi" v-model="mainData.satisProjesiName"
									ref="entity_satisprojesi" label="Satış Projesi" entityName="opportunity"
									nameField="name" :state="true"
									@itemSelected="satisprojesiSelected = $event"
									@itemCleared="satisprojesiSelected = null" disabled>
								</EntityLookup>
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<EntityLookup id="teklif" v-model="mainData.teklifName"
									ref="entity_teklif" label="Teklif" entityName="quote"
									nameField="name" :state="true"
									@itemSelected="teklifSelected = $event"
									@itemCleared="teklifSelected = null" disabled>
								</EntityLookup>
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<EntityLookup id="parabirimi" v-model="mainData.paraBirimiName" ref="entity_paraBirimi"
									label="Para Birimi" entityName="transactioncurrency" nameField="currencyname" disabled
									:state="true" @itemSelected="paraBirimiSelected = $event"
									@itemCleared="paraBirimiSelected = null"></EntityLookup>
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<EntityLookup id="fiyatListesi" v-model="mainData.fiyatListesiName"
									ref="entity_fiyatListesi" label="Fiyat Listesi" entityName="pricelevel" disabled
									nameField="name" :state="true" @itemSelected="fiyatListesiSelected = $event"
									@itemCleared="fiyatListesiSelected = null"></EntityLookup>
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="mazakSiparisTarihi">Mazak Sipariş Tarihi</label>
								<Calendar showButtonBar id="mazakSiparisTarihi" v-model="mainData.mazakSiparisTarihi"
									autocomplete="off" :showIcon="true" :disabled="isDisabled" />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="marka">Ürün Marka <span style="color:red">*</span></label>
								<Dropdown id="marka" v-model="mainData.urunMarka" :options="SM_marka" optionLabel="Value"
									optionValue="AttributeValue" placeholder="Ürün Marka" :filter="true" :disabled="true" />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="bolge">Bölge</label>
								<Dropdown id="bolge" v-model="mainData.bolge" :options="SM_bolge" optionLabel="Value" optionValue="AttributeValue" placeholder="Bölge" :disabled="true" />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="frmno">FRM No</label>
								<InputText id="frmno" type="text" v-model="mainData.frmNo" :disabled="isDisabled" />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="pono">Po No</label>
								<InputText id="pono" type="text" v-model="mainData.poNo" :disabled="isDisabled" />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="urunTipi">Ürün Tipi</label>
								<Dropdown id="urunTipi" v-model="mainData.urunTipi" :options="SM_UrunTipi" optionLabel="Value" optionValue="AttributeValue" placeholder="Ürün Tipi" :filter="true" :disabled="true" />
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="col-12">
				<div class="card">
					<h5>AÇIKLAMALAR</h5>
					<div class="grid">
						<div class="col-12">
							<div class="field p-fluid">
								<label for="aciklama">Açıklama</label>
								<Textarea id="aciklama" rows="5" :autoResize="false" v-model="mainData.aciklama" :disabled="isDisabled" />
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="col-12">
				<div class="card">
					<h5>SİPARİŞ DETAYI</h5>
					<div class="grid">
						<div class="col-3">
							<div class="field p-fluid">
								<label for="sozlesmeTarihi">Sözleşme Tarihi</label>
								<Calendar showButtonBar id="sozlesmeTarihi" v-model="mainData.sozlesmeTarihi" autocomplete="off" :showIcon="true" :disabled="isDisabled" />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="kapora">Kapora</label>
								<InputText id="kapora" type="text" v-model="mainData.kapora" :disabled="isDisabled" />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="teslimSekli">Teslim Şekli</label>
								<Dropdown id="teslimSekli" v-model="mainData.teslimSekli" :options="SM_teslimSekli"
									optionLabel="Value" optionValue="AttributeValue" placeholder="Teslim Şekli"
									:showClear="true" :filter="true" :disabled="isDisabled" />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="garanti">Garanti</label>
								<Dropdown id="garanti" v-model="mainData.garanti" :options="SM_garanti"
									optionLabel="Value" optionValue="AttributeValue" placeholder="Garanti" :showClear="true" :disabled="isDisabled" />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="yuklemeyeri">Yükleme Yeri</label>
								<Dropdown id="yuklemeyeri" v-model="mainData.yuklemeYeri" :options="SM_yuklemeyeri"
									optionLabel="Value" optionValue="AttributeValue" placeholder="Yükleme Yeri" :showClear="true" :disabled="isDisabled" />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="siparisYuklemeTarihi">Sözleşme Yükleme Tarihi</label>
								<InputText id="siparisYuklemeTarihi" type="text" v-model="mainData.sozlesmeYuklemeTarihi" :disabled="isDisabled" />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="sozlesmeIleKontrolEdildimi">Sözleşme ile Kontrol Edildi mi?</label><br />
								<InputSwitch id="sozlesmeIleKontrolEdildimi" v-model="mainData.sozlesmeIleKontrolEdildimi" :disabled="isDisabled" />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="tesvikVarmi">Teşvik Var mı?</label>
								<Dropdown id="tesvikVarmi" v-model="mainData.tesvikVarmi" :options="SM_tesvikVarmi"
									optionLabel="Value" optionValue="AttributeValue" placeholder="Teşvik Var mı?" :showClear="true" :disabled="isDisabled" />
							</div>
						</div>
						<div class="col-12">
							<div class="field p-fluid">
								<label for="odemeSekli">Ödeme Şekli</label>
								<Textarea id="odemeSekli" rows="4" :autoResize="true" v-model="mainData.odemeSekli" :disabled="isDisabled" />
							</div>
						</div>

					</div>
				</div>
			</div>

			<div class="col-12">
				<div class="card">
					<h5>TEZGAH BİLGİLERİ</h5>
					<div class="grid">
						<div class="col-2">
							<div class="field p-fluid">
								<label for="ncDili">NC Dili?</label>
								<Dropdown id="ncDili" v-model="mainData.ncDili" :options="SM_ncDili" optionLabel="Value"
									optionValue="AttributeValue" placeholder="NC Dili?" :showClear="true" :disabled="isDisabled" />
							</div>
						</div>
						<div class="col-2">
							<div class="field p-fluid">
								<label for="uyariLevhaDili">Uyarı Levha Dili</label>
								<Dropdown id="uyariLevhaDili" v-model="mainData.uyariLevhaDili" :options="SM_uyariLevhaDili" optionLabel="Value" optionValue="AttributeValue" placeholder="Uyarı Levha Dili" :showClear="true" :disabled="isDisabled" />
							</div>
						</div>
						<div class="col-2">
							<div class="field p-fluid">
								<label for="kontrolUnitesi">Kontrol Ünitesi</label>
								<Dropdown id="kontrolUnitesi" v-model="mainData.kontrolUnitesi" :options="SM_kontrolUnitesi" optionLabel="Value" optionValue="AttributeValue" placeholder="Kontrol Ünitesi" :showClear="true" :disabled="isDisabled" />
							</div>
						</div>
						<div class="col-2">
							<div class="field p-fluid">
								<label for="voltaj">Voltaj</label>
								<Dropdown id="voltaj" v-model="mainData.voltaj" :options="SM_voltaj" optionLabel="Value" optionValue="AttributeValue" placeholder="Voltaj" :showClear="true" :disabled="isDisabled" />
							</div>
						</div>
						<div class="col-2">
							<div class="field p-fluid">
								<label for="renk">Renk</label>
								<Dropdown id="renk" v-model="mainData.renk" :options="SM_renk" optionLabel="Value" optionValue="AttributeValue" placeholder="Renk" :showClear="true" :disabled="isDisabled" />
							</div>
						</div>
						<div class="col-2">
							<div class="field p-fluid">
								<label for="manualler">Manualler</label>
								<Dropdown id="manualler" v-model="mainData.manualler" :options="SM_manualler" optionLabel="Value" optionValue="AttributeValue" placeholder="Manualler" :showClear="true" :disabled="isDisabled" />
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="col-12">
				<div class="card">
					<h5>TOPLAMLAR</h5>
					<div class="grid">
						<div class="col-3">
							<div class="field p-fluid">
								<label for="araToplamYeniBirimFiyat">Ara Toplam (Yeni Birim Fiyat)</label>
								<InputNumber id="araToplamYeniBirimFiyat" v-model="mainData.araToplamYeniBirimFiyat"
									:suffix="getCurrency()" disabled />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="araToplam">Ara Toplam</label>
								<InputNumber id="araToplam" v-model="mainData.araToplam" :suffix="getCurrency()"
									disabled />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="urunlerdenGelenToplamIndirim">Ürünlerden Gelen Toplam İndirim</label>
								<InputNumber id="urunlerdenGelenToplamIndirim"
									v-model="mainData.urunlerdenGelenToplamIndirim" :suffix="getCurrency()" disabled />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="urunlerdenGelenToplamIndirimOran">Ürünlerden Gelen Toplam İndirim
									(%)</label>
								<InputNumber id="urunlerdenGelenToplamIndirimOran"
									v-model="mainData.urunlerdenGelenToplamIndirimYuzde" suffix="%" disabled />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="indirim">İndirim</label>
								<InputNumber id="indirim" v-model="mainData.indirim1" :suffix="getCurrency()"
									disabled />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="indirimOran">İndirim (%)</label>
								<InputNumber id="indirimOran" v-model="mainData.indirim1yuzde" suffix="%" disabled />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="indirimToplami">İndirim Toplamı</label>
								<InputNumber id="indirimToplami" v-model="mainData.indirimToplami"
									:suffix="getCurrency()" disabled />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="toplamindirimOrani">Toplam İndirim (%)</label>
								<InputNumber id="toplamindirimOrani" v-model="mainData.toplamIndirimYuzde" suffix="%"
									disabled />
							</div>
						</div>
						<div class="col-9">
							<div class="field p-fluid">
								<label for="indirimAciklamasi">İndirim Açıklaması</label>
								<InputText id="indirimAciklamasi" type="text" v-model="mainData.indirimAciklamasi" />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="euroTutar">Euro Tutar</label>
								<InputNumber id="euroTutar" v-model="mainData.euroTutar" suffix=" €" />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="navlunOncesiTutar">Navlun Öncesi Tutar</label>
								<InputNumber id="navlunOncesiTutar" v-model="mainData.navlunOncesiTutar" :suffix="getCurrency()" disabled />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="istenilenToplam">İstenilen Toplam</label>
								<InputNumber id="istenilenToplam" v-model="mainData.istenilenToplam" :suffix="getCurrency()" disabled />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="navlunMasrafi">Navlun Masrafı</label>
								<InputNumber id="navlunMasrafi" v-model="mainData.navlunMasrafi" :suffix="getCurrency()" disabled />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="genelToplam">Genel Toplam</label>
								<InputNumber id="genelToplam" v-model="mainData.genelToplam" :suffix="getCurrency()" disabled />
							</div>
						</div>

					</div>
				</div>
			</div>

			<div class="col-12">
				<div class="card">
					<h5>SİPARİŞ ÜRÜNLERİ</h5>
					<DataTable :value="siparisUrunleriData" v-model:selection="selectedSiparisUrunleri" :rowHover="true" class="p-datatable" responsiveLayout="scroll" @row-dblclick="OnDblClickSiparisUrun($event)">
						<template #empty>
							Kayıt Bulunamadı.
						</template>
						<template #loading>
							Veriler yükleniyor, lütfen bekleyiniz...
						</template>
						<template #header>
							<div class="flex justify-content-between">
								<div style="width: 30%;">
									<Button label="Seçimi Temizle" icon="pi pi-trash" class="p-button p-button-success" @click="selectedSiparisUrunleri = []" />
									<Button v-if="selectedSiparisUrunleri.length > 0" :label="tedarikGuncelle" icon="pi pi-clone" class="p-button p-button-primary ml-3" @click="displaySiparisUrun = true" />
								</div>
								<div>
                                    <Button v-if="selectedSiparisUrunleri.length > 0" :label="siparisUrunleriCount" icon="pi pi-trash" class="p-button p-button-danger" @click="confirmSelectedDeleteProducts()" />
								</div>
							</div>
						</template>
						<Column selectionMode="multiple" style="flex: 0 0 2rem" headerStyle="width: 3rem"></Column>
						<Column field="urunKodu" header="Ürün kodu" headerStyle="width:100px" sortable></Column>
						<Column field="urun" header="Ürün" sortable>
							<template #body="slotProps">
								<a class='lookuplink' @click="OnClickProductUrl(slotProps.data.urunId)">{{ slotProps.data.urun }}</a> 
							</template>
						</Column>
						<Column field="birimFiyati" header="Birim Fiyatı" headerStyle="width:90px" sortable bodyStyle="text-align: right">
							<template #body="slotProps">
								{{ formatNumber(slotProps.data.birimFiyati) }}
							</template>
						</Column>
						<Column field="yeniBirimFiyat" header="Yeni Birim Fiyatı" headerStyle="width:90px" sortable bodyStyle="text-align: right">
							<template #body="slotProps">
								{{ formatNumber(slotProps.data.yeniBirimFiyat) }}
							</template>
						</Column>
						<Column field="miktar" header="Miktar" headerStyle="width:90px" sortable bodyStyle="text-align: center">
						</Column>
						<Column field="toplamTutar" header="Toplam Tutar" headerStyle="width:90px" sortable bodyStyle="text-align: right">
							<template #body="slotProps">
								{{ formatNumber(slotProps.data.toplamTutar) }}
							</template>
						</Column>
						<Column field="tedarik" header="Tedarik" headerStyle="width:100px; text-align: right" sortable bodyStyle="text-align: right"></Column>
					</DataTable>
				</div>
			</div>

			<div class="col-12">
				<Annotation objectTypeCode="1088" logicalName="salesorder" :objectId="entityId" ></Annotation>
			</div>
		</div>

		<Dialog v-model:visible="displaySiparisUrun" :breakpoints="{'960px': '90vw', '640px': '90vw'}" :style="{width: '50vw'}" header="Tedarik Güncelle" :modal="true" :closable="true" :showHeader="true" :draggable="true">
            <br>
            <div class="p-4">
                <div class="grid">
					<div class="col-12">
						<div class="field p-fluid">
							<h5>{{ tedarikGuncelle }}</h5>
						</div>
					</div>
                    <div class="col-12">
						<div class="field p-fluid">
							<label for="tedarik">Tedarik</label>
							<Dropdown id="tedarik" v-model="dataSiparisUrun.tedarikId" :options="SM_tedarik" optionLabel="Value" optionValue="AttributeValue" placeholder="Tedarik" :showClear="true" :filter="true" />
						</div>
					</div>
                </div>

                <Button label="Kaydet" class="mt-3 p-button-secondary" icon="pi pi-save" @click="OnSaveSiparisUrun" />
            </div>
            
        </Dialog>
	</form>
</template>

<script>
import CrmService from "../service/CrmService";
import { useVuelidate } from "@vuelidate/core";
import { helpers, required } from "@vuelidate/validators";

export default {
	setup: () => ({ v$: useVuelidate() }),
	data() {
		return {
			crmService: null,
			submitted: false,
			OBJECT_TYPE_CODE: 1088,
			mainData: {},
			siparisUrunleriData: [],
			selectedSiparisUrunleri: [],

			displaySiparisUrun: false,
			dataSiparisUrun: {},

			nestedMenuitems: [
				{
					label: 'Kaydet',
					icon: 'pi pi-fw pi-save',
					command: () => { this.OnSave(false); },
					visible: () => !this.isDisabled
				},
				{
					label: 'Kaydet & Kapat',
					icon: 'pi pi-fw pi-save',
					command: () => { this.OnSave(true); },
					visible: () => !this.isDisabled
				},
				{
                    label: "Sil",
                    icon: "pi pi-fw pi-trash",
                    command: () => { this.OnDelete(); },
					visible: () => this.isDeleted
                },
				{
					label: 'Tezgah Oluştur',
					icon: 'pi pi-fw pi-send',
					visible: () => this.isTezgahOlusturVisible(),
					command: () => { this.createTezgah(); },
				},
				{
					label:'Sipariş Yazdır',
					icon:'pi pi-fw pi-print',
					items:[
						{
							label:'Sözleşme Euro', command: () => { this.GotoReport('SozlesmeEuro', 'salesOrderId'); },
							icon:'pi pi-fw pi-print'
						},
						{
							label:'Sözleşme Yen', command: () => { this.GotoReport('SozlesmeYen', 'salesOrderId'); },
							icon:'pi pi-fw pi-print'
						},
						{
							label:'Sözleşme EF (Euro/Yen)', command: () => { this.GotoReport('SozlesmeEFEuro', 'salesOrderId'); },
							icon:'pi pi-fw pi-print'
						},
						{
							label:'Proforma Euro (Euro/Yen)', command: () => { this.GotoReport('ProformaInvoice', 'salesOrderId'); },
							icon:'pi pi-fw pi-print'
						},
                   ]
                },
			],
			selectedUrunAilesi: null, //{ "9e344a2f-2208-4fa3-b0e3-0f015bd25bc7": true },
			urunAilesiNodes: [],
		}
	},
	async created() {
		this.crmService = new CrmService();

		console.log('created');
		window.addEventListener('scroll', this.handleScroll);

		this.OnLoad();
	},
	mounted() {

	},
	computed: {
		entityId() {
			return this.$route.params.id;
		},
		profileData() {
			return this.$store.getters.getProfile;
		},
		isDisabled: function() {
			if (this.profileData) {
				if (this.profileData.moduller) {
					const filtered = this.profileData.moduller.filter(x => x.name == 'Siparişler');
					if (filtered.length > 0) {
						return filtered[0].isWrite == false;
					}
				}
			}

			return true;
		},
		isDeleted: function() {
			if (this.profileData) {
				if (this.profileData.moduller) {
					const filtered = this.profileData.moduller.filter(x => x.name == 'Siparişler');
					if (filtered.length > 0) {
						return filtered[0].isDelete == true;
					}
				}
			}

			return false;
		},
		tedarikGuncelle: function () {
			return this.selectedSiparisUrunleri.length + " adet Tedarik Güncelle";
		},
		SM_satisYontemi: function () {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_satisyontemi');
		},
		SM_teslimSekli: function () {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'freighttermscode');
		},
		SM_durum: function () {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'statuscode');
		},
		SM_marka: function () {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_urunmarka');
		},
		SM_UrunTipi: function() {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_uruntipi');
		},
		SM_garanti: function () {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_garanti');
		},
		SM_yuklemeyeri: function () {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_yuklemeyeri');
		},
		SM_tesvikVarmi: function () {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_tesvikvarmi');
		},
		SM_ncDili: function () {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_ncdili');
		},
		SM_uyariLevhaDili: function () {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_uyarilevha');
		},
		SM_kontrolUnitesi: function () {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_kontrolunitesi');
		},
		SM_voltaj: function () {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_voltaj');
		},
		SM_renk: function () {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_renk');
		},
		SM_manualler: function () {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_manualler');
		},
		SM_tedarik: function () {
			return this.$store.getters.getStringMapByEntityId(1089, 'bm_tedarik');
		},
		SM_bolge: function () {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_bolge');
		},
		teklifVerenKisiSelected: {
			get: function () {
				if (this.mainData["siparisVerenKisiId"]) {
					return { "Value": this.mainData["siparisVerenKisiId"], "Name": this.mainData["siparisVerenKisiName"] }
				} else { return null; }
			},
			set: function (newValue) {
				if (newValue == null) {
					this.mainData["siparisVerenKisiId"] = null;
					this.mainData["siparisVerenKisiName"] = null;
				}
				else {
					this.mainData["siparisVerenKisiId"] = newValue.Value;
					this.mainData["siparisVerenKisiName"] = newValue.Name;
				}
			}
		},
		tezgahSelected: {
			get: function () {
				if (this.mainData["tezgahId"]) {
					return { "Value": this.mainData["tezgahId"], "Name": this.mainData["tezgahName"] }
				} else { return null; }
			},
			set: function (newValue) {
				if (newValue == null) {
					this.mainData["tezgahId"] = null;
					this.mainData["tezgahName"] = null;
				}
				else {
					this.mainData["tezgahId"] = newValue.Value;
					this.mainData["tezgahName"] = newValue.Name;
				}
			}
		},
		firmaSelected: {
			get: function () {
				if (this.mainData["firmaId"]) {
					return { "Value": this.mainData["firmaId"], "Name": this.mainData["firmaName"] }
				} else { return null; }
			},
			set: function (newValue) {
				if (newValue == null) {
					this.mainData["firmaId"] = null;
					this.mainData["firmaName"] = null;
				}
				else {
					this.mainData["firmaId"] = newValue.Value;
					this.mainData["firmaName"] = newValue.Name;
				}
			}
		},
		urunSelected: {
			get: function () {
				if (this.mainData["urunId"]) {
					return { "Value": this.mainData["urunId"], "Name": this.mainData["urunName"] }
				} else { return null; }
			},
			set: function (newValue) {
				if (newValue == null) {
					this.mainData["urunId"] = null;
					this.mainData["urunName"] = null;
				}
				else {
					this.mainData["urunId"] = newValue.Value;
					this.mainData["urunName"] = newValue.Name;
				}
			}
		},
		ilgiliKisiSelected: {
			get: function () {
				if (this.mainData["ilgiliKisiId"]) {
					return { "Value": this.mainData["ilgiliKisiId"], "Name": this.mainData["ilgiliKisiName"] }
				} else { return null; }
			},
			set: function (newValue) {
				if (newValue == null) {
					this.mainData["ilgiliKisiId"] = null;
					this.mainData["ilgiliKisiName"] = null;
				}
				else {
					this.mainData["ilgiliKisiId"] = newValue.Value;
					this.mainData["ilgiliKisiName"] = newValue.Name;
				}
			}
		},
		satisprojesiSelected: {
			get: function () {
				if (this.mainData["satisprojesiId"]) {
					return { "Value": this.mainData["satisprojesiId"], "Name": this.mainData["satisprojesiName"] }
				} else { return null; }
			},
			set: function (newValue) {
				if (newValue == null) {
					this.mainData["satisprojesiId"] = null;
					this.mainData["satisprojesiName"] = null;
				}
				else {
					this.mainData["satisprojesiId"] = newValue.Value;
					this.mainData["satisprojesiName"] = newValue.Name;
				}
			}
		},
		teklifSelected: {
			get: function () {
				if (this.mainData["teklifId"]) {
					return { "Value": this.mainData["teklifId"], "Name": this.mainData["teklifName"] }
				} else { return null; }
			},
			set: function (newValue) {
				if (newValue == null) {
					this.mainData["teklifId"] = null;
					this.mainData["teklifName"] = null;
				}
				else {
					this.mainData["teklifId"] = newValue.Value;
					this.mainData["teklifName"] = newValue.Name;
				}
			}
		},
		paraBirimiSelected: {
			get: function () {
				if (this.mainData["paraBirimiId"]) {
					return { "Value": this.mainData["paraBirimiId"], "Name": this.mainData["paraBirimiName"] }
				} else { return null; }
			},
			set: function (newValue) {
				if (newValue == null) {
					this.mainData["paraBirimiId"] = null;
					this.mainData["paraBirimiName"] = null;
				}
				else {
					this.mainData["paraBirimiId"] = newValue.Value;
					this.mainData["paraBirimiName"] = newValue.Name;
				}
			}
		},
		fiyatListesiSelected: {
			get: function () {
				if (this.mainData["fiyatListesiId"]) {
					return { "Value": this.mainData["fiyatListesiId"], "Name": this.mainData["fiyatListesiName"] }
				} else { return null; }
			},
			set: function (newValue) {
				if (newValue == null) {
					this.mainData["fiyatListesiId"] = null;
					this.mainData["fiyatListesiName"] = null;
				}
				else {
					this.mainData["fiyatListesiId"] = newValue.Value;
					this.mainData["fiyatListesiName"] = newValue.Name;
				}
			}
		},
		bankaSelected: {
			get: function () {
				if (this.mainData["bankaId"]) {
					return { "Value": this.mainData["bankaId"], "Name": this.mainData["bankaName"] }
				} else { return null; }
			},
			set: function (newValue) {
				if (newValue == null) {
					this.mainData["bankaId"] = null;
					this.mainData["bankaName"] = null;
				}
				else {
					this.mainData["bankaId"] = newValue.Value;
					this.mainData["bankaName"] = newValue.Name;
				}
			}
		},
	},
	methods: {
		async OnLoad() {
			let loader = this.$loading.show({
				container: this.$refs.smsContainer
			});

			if (this.entityId != null) {
				try {
					debugger;

					let data = await this.crmService.getSiparisById(this.entityId);
					if (data.yetkiVarmi == false) {
						this.$router.replace({ name: 'accessdenied' });
					}
					
					if (data.jsonData.length > 0) {
						this.mainData = data.jsonData[0];
						this.mainData["entityId"] = this.entityId;
						this.siparisUrunleriData = data.siparisUrunleriData;

						const pageHistoryItem = { entityLetter: 'Sip', entityName: 'salesorder', ID: this.mainData.entityId, title: this.mainData.siparisKimligi + '\r\n\r\n' + this.mainData.firmaName };
						this.$store.commit('SET_PAGEHISTORY', pageHistoryItem);
						
						this.mainData["voltaj"] = 1; //380V
						this.mainData["renk"] = 1; //STANDART
						this.mainData["manualler"] = 1; //ENGLISH

						if (this.mainData["siparisVerenKisiId"]) {
							this.$refs.entity_teklifVerenKisi.setDefaultData({ "Value": this.mainData["siparisVerenKisiId"], "Name": this.mainData["siparisVerenKisiName"] });
						}

						if (this.mainData["tezgahId"]) {
							this.$refs.entity_tezgah.setDefaultData({ "Value": this.mainData["tezgahId"], "Name": this.mainData["tezgahName"] });
						}

						if (this.mainData["urunAilesiId"]) {
							this.selectedUrunAilesi = { [this.mainData["urunAilesiId"]]: true };
						}

						if (this.mainData["firmaId"]) {
							this.$refs.entity_firma.setDefaultData({ "Value": this.mainData["firmaId"], "Name": this.mainData["firmaName"] });
						}

						if (this.mainData["urunId"]) {
							this.$refs.entity_urun.setDefaultData({ "Value": this.mainData["urunId"], "Name": this.mainData["urunName"] });
						}

						if (this.mainData["ilgiliKisiId"]) {
							this.$refs.entity_ilgilikisi.setDefaultData({ "Value": this.mainData["ilgiliKisiId"], "Name": this.mainData["ilgiliKisiName"] });
						}

						if (this.mainData["satisProjesiId"]) {
							this.$refs.entity_satisprojesi.setDefaultData({ "Value": this.mainData["satisProjesiId"], "Name": this.mainData["satisProjesiName"] });
						}

						if (this.mainData["teklifId"]) {
							this.$refs.entity_teklif.setDefaultData({ "Value": this.mainData["teklifId"], "Name": this.mainData["teklifName"] });
						}

						if (this.mainData["paraBirimiId"]) {
							this.$refs.entity_paraBirimi.setDefaultData({ "Value": this.mainData["paraBirimiId"], "Name": this.mainData["paraBirimiName"] });
						}

						if (this.mainData["fiyatListesiId"]) {
							this.$refs.entity_fiyatListesi.setDefaultData({ "Value": this.mainData["fiyatListesiId"], "Name": this.mainData["fiyatListesiName"] });
						}

						if (this.mainData["siparisTarihi"]) {
							this.mainData["siparisTarihi"] = new Date(this.mainData.siparisTarihi);
						}

						if (this.mainData["sozlesmeTarihi"]) {
							this.mainData["sozlesmeTarihi"] = new Date(this.mainData.sozlesmeTarihi);
						}

						if (this.mainData["mazakSiparisTarihi"]) {
							this.mainData["mazakSiparisTarihi"] = new Date(this.mainData.mazakSiparisTarihi);
						}

						if (this.mainData["bankaId"]) {
							this.$refs.entity_banka.setDefaultData({ "Value": this.mainData["bankaId"], "Name": this.mainData["bankaName"] });
						}
					}

					this.urunAilesiNodes = await this.crmService.GetSubjects();
				} catch (error) {
					console.log(error);
				}
				finally {
					loader.hide();
				}
			}
			else {
				loader.hide();
			}
		},
		async OnSave(kapat) {
			debugger;

			this.submitted = true;
			this.v$.$touch();

			if (this.v$.$invalid) {
				this.v$.$errors.forEach(element => {
					this.$toast.add({ severity: 'error', summary: 'Eksik alanlar var.', detail: element.$message, life: 3500 });
				});
			} else {
				try {
					const response = await this.crmService.getSiparisUpdate(this.mainData);
					if (response) {
						if (response.hata == true) {
							this.$toast.add({severity:'error', summary: 'Kaydedilemedi', detail: response.hataAciklamasi, life: 5000});
						}else {
							this.$toast.add({severity:'success', summary: 'Bilgi', detail:'Başarıyla Kaydedildi', life: 3000});
							if (window.opener) {
								window.opener.window.postMessage({'tag': 'refresh'}, window.location.origin);
							}
							
							if (kapat) {
								if (window.opener) {
									//window.opener.location.reload();
								}
								setTimeout(() => {
									window.close();
								}, 1000);
							}
						}
					}
				} catch (error) {
					this.$toast.add({severity:'error', summary: 'Eksik alanlar var.', detail: error.message, life: 3500});
				}
			}
		},
		async OnDelete(){
            this.deleteItem('salesorder', this.entityId);
        },
		async createTezgah(){
			console.log('createTezgah');
			debugger;

			try {
				const response = await this.crmService.createTezgah(this.entityId);
				debugger;
				if (response) {
					if (response.hata == true) {
						this.$toast.add({severity:'error', summary: 'Tezgah Oluştur', detail: response.hataAciklamasi, life: 5000});
					}else {
						this.$toast.add({severity:'success', summary: 'Bilgi', detail:'Tezgah Başarıyla Oluşturuldu', life: 3000});
						setTimeout(() => {
							this.OnLoad();
						}, 500);
					}
				}
			} catch (error) {
				this.$toast.add({severity:'error', summary: 'HATA', detail: error.message, life: 3500});
			}
		},
		async OnSaveSiparisUrun(event){
			try {
				const entityIdList = this.selectedSiparisUrunleri.map(obj => obj.entityId);
				const postData = {
					tedarikId : this.dataSiparisUrun.tedarikId,
					list: entityIdList
				};
				const response = await this.crmService.updateSalesOrderDetail(postData);
				if (response) {
					if (response.hata == true) {
						this.$toast.add({severity:'error', summary: 'Kaydedilemedi', detail: response.hataAciklamasi, life: 5000});
					}else {
						this.$toast.add({severity:'success', summary: 'Bilgi', detail:'Başarıyla Kaydedildi', life: 3000});
						setTimeout(() => {
							this.selectedSiparisUrunleri = [];
							this.dataSiparisUrun = {};
							this.displaySiparisUrun = false;
							this.OnLoad();
						}, 1000);
					}
				}
			} catch (error) {
				this.$toast.add({severity:'error', summary: 'Eksik alanlar var.', detail: error.message, life: 3500});
			}

			console.log(event);
		},
		urunAilesiSelect(event) {
			this.mainData["urunAilesiId"] = event.key;
			this.mainData["urunAilesiName"] = event.label;
		},
		OnKazandiOlarakKapat() {
			console.log('Kazandi');
		},
		OnKayipOlarakKapat() {
			console.log('Kayıp');
		},
		isTezgahOlusturVisible(){
			let isSeviyeA = (this.profileData.guvenlikSeviyeleri.indexOf("Seviye A") > -1);
			if (isSeviyeA == true) {
				return true;
			}
			else {
				return false;
			}
		},
		OnClickProductUrl(entityId){
            let features = 'directories=no,menubar=no,status=no,titlebar=no,toolbar=no';

			let routeData = this.$router.resolve({ name: "product", params: { id: entityId } });
			window.open(routeData.href, '_blank', 'yenipencere', features);
		},
		GotoReport(Name, ParamName){
			const reportUrl = process.env.VUE_APP_WEBAPP_URL + "/SSRS_ReportViewer.aspx?name=" + Name + "&" + ParamName + "=" + this.entityId;
			window.open(reportUrl, 'qqq', '_blank');
		},
		formatNumber(value) {
			debugger;
			if (value != undefined) {
				return value.toLocaleString('tr-TR') + ' ' + this.getCurrency();
			}
		},
		getCurrency() {
			let paraBirimi = '';
			switch (this.mainData.paraBirimiName) {
				case "Dolar":
					paraBirimi = " $"
					break;
				case "Euro":
					paraBirimi = " €"
					break;
				case "İsviçre Frankı":
					paraBirimi = " SFr."
					break;
				case "Türk Lirası":
					paraBirimi = " ₺"
					break;
				case "Yen":
					paraBirimi = " ¥"
					break;

				default:
					break;
			}
			return paraBirimi;
		},
		handleScroll() {
			const el = document.getElementById('stickBar');

			if (window.scrollY > 50) {
				el.style.boxShadow = '0px 2px 1px rgba(0, 0, 0, 0.09), 0px 4px 2px rgba(0, 0, 0, 0.09), 0px 8px 4px rgba(0, 0, 0, 0.09), 0px 16px 8px rgba(0, 0, 0, 0.09), 0px 32px 16px rgba(0, 0, 0, 0.09)';
			}
			else {
				el.style.boxShadow = '';
			}
		},
	},
	watch: {
		profileData(val) {
			if (val) {
				if (val.moduller) {
					const filtered = val.moduller.filter(x => x.name == 'Siparişler');
					if (filtered.length == 0) {
						this.$router.replace({ name: 'accessdenied' });
					}
				}
			}
		}
	},
	validations() {
		return {
			mainData: {
				ilgiliKisiId: {
					required: helpers.withMessage('İlgili Kişi alanı dolu olmalıdır!', required),
				},
				siparisTarihi: {
					required: helpers.withMessage('Sipariş Tarihi alanı dolu olmalıdır!', required),
				},
				satisYontemi: {
					required: helpers.withMessage('Satış Yöntemi alanı dolu olmalıdır!', required),
				},
				urunMarka: {
					required: helpers.withMessage('Ürün Marka Tarihi alanı dolu olmalıdır!', required),
				},
			},
		}
	}
}
</script>

<style lang="scss" scoped>
	::v-deep(.lookuplink:hover) {
        text-decoration: underline;
        color: rgb(0, 0, 0);
		cursor: pointer;
    }
</style>
